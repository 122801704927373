.header-border {
  border-left: 2px solid rgba(0, 0, 0, 0.2) !important;
  border-right: 2px solid rgba(0, 0, 0, 0.2) !important;
}

.header-border-bottom {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
}

.ds-tables .MuiOutlinedInput-input {
  text-align: center;
}

.header-border-print {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}

.header-border-bottom-print {
  border-bottom: 1px solid black !important;
}
